import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ graphData, showLegend = false }) => {
  return (
    <div className="relative w-full flex justify-center">
      <p
        className={
          showLegend
            ? "font-semibold absolute text-xl inset-0 flex items-center justify-center z-10 mt-8"
            : "font-semibold absolute text-xl inset-0 flex items-center justify-center z-10"
        }
      >
        {parseInt(graphData.centerText) + "%"}
      </p>
      <Doughnut
        data={graphData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          cutout: "60%",
          plugins: {
            legend: {
              onClick: null,
            },
          },
        }}
      />
    </div>
  );
};

export default PieChart;
