import React from "react";
import {
  HiCheckCircle,
  HiOutlineInformationCircle,
  HiOutlineExclamation,
  HiOutlineExclamationCircle,
} from "react-icons/hi";
import { Avatar, Button, Dialog } from "components/ui";
import PropTypes from "prop-types";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaPeopleArrows } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { BsBroadcastPin } from "react-icons/bs";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { IoCloudUploadOutline } from "react-icons/io5";

const StatusIcon = ({ status, size }) => {
  switch (status) {
    case "confirm":
      return (
        <Avatar
          className="bg-stroke-100 text-primary dark:bg-stroke-500/20 dark:text-stroke-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <AiOutlineQuestionCircle size={32} />
          </span>
        </Avatar>
      );
    case "swap":
      return (
        <Avatar
          className="bg-stroke-100 text-primary dark:bg-stroke-500/20 dark:text-stroke-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <FaPeopleArrows size={32} />
          </span>
        </Avatar>
      );
    case "broadcast":
      return (
        <Avatar
          className="bg-stroke-100 text-primary dark:bg-stroke-500/20 dark:text-stroke-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <BsBroadcastPin />
          </span>
        </Avatar>
      );
    case "location":
      return (
        <Avatar
          className="bg-stroke-100 text-primary dark:bg-stroke-500/20 dark:text-stroke-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <GrLocation />
          </span>
        </Avatar>
      );
    case "info":
      return (
        <Avatar
          className="bg-blue-100 text-blue-600 dark:bg-blue-500/20 dark:text-blue-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <HiOutlineInformationCircle size={32} />
          </span>
        </Avatar>
      );
    case "success":
      return (
        <Avatar
          className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <HiCheckCircle size={32} />
          </span>
        </Avatar>
      );
    case "warning":
      return (
        <Avatar
          className="text-amber-600 bg-amber-100 dark:text-amber-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <HiOutlineExclamationCircle size={32} />
          </span>
        </Avatar>
      );
    case "danger":
      return (
        <Avatar
          className="text-red-600 bg-red-100 dark:text-red-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <HiOutlineExclamation size={32} />
          </span>
        </Avatar>
      );
    case "edit":
      return (
        <Avatar
          className="text-[#3C50E0] bg-[#E2E8F0] dark:text-red-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <MdOutlineEdit size={32} />
          </span>
        </Avatar>
      );
    case "delete":
      return (
        <Avatar
          className="text-red-600 bg-red-100 dark:text-red-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <RiDeleteBin5Line size={32} />
          </span>
        </Avatar>
      );
    case "upload":
      return (
        <Avatar
          className="text-[#3C50E0] bg-[#E2E8F0] dark:text-red-100"
          shape="circle"
          size={52}
        >
          <span className="text-2xl">
            <IoCloudUploadOutline size={32} />
          </span>
        </Avatar>
      );

    default:
      return null;
  }
};

const ConfirmDialog = (props) => {
  const {
    type,
    title,
    children,
    onCancel,
    width,
    onConfirm,
    cancelText,
    confirmText,
    confirmButtonColor,
    flag,
    ...rest
  } = props;

  const handleCancel = () => {
    onCancel?.();
  };

  const handleConfirm = () => {
    onConfirm?.();
  };

  return (
    <Dialog contentClassName="px-0" width={width} {...rest}>
      <div className="px-12 py-8 flex flex-col items-center w-full max-h-[90vh] overflow-auto">
        <div>
          <StatusIcon status={type} />
        </div>
        <div className="w-full">
          <h5 className="my-5 text-center">{title}</h5>
          {children}
        </div>
      </div>
      {onConfirm && (
        <div className="text-center px-6 pt-6 rounded-bl-lg rounded-br-lg">
          <Button
            size="md"
            variant="solid"
            onClick={handleConfirm}
            color={confirmButtonColor}
            disabled={flag}
            shape="none"
          >
            {confirmText}
          </Button>
        </div>
      )}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  type: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger",
    "none",
    "swap",
    "confirm",
  ]),
};

ConfirmDialog.defaultProps = {
  type: "info",
  cancelText: "Cancel",
  confirmText: "Confirm",
  flag: false,
};

export default ConfirmDialog;
